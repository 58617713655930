<template>
  <div>
    <div class="content">
      <h1>{{ $t('page.preface.title') }}</h1>
      <b-message
        v-if="!hasIncorporationAccess"
        class="mt-6"
        type="is-warning"
      >
        <b>{{ $t('page.preface.warning') }}</b>
        <p>{{ $t('page.preface.refundWarning') }}</p>
      </b-message>
      <b-message
        class="my-6"
        type="is-warning"
      >
        <p>{{ $t('page.preface.localeWarning') }}</p>
        <b-button
          type="is-text is-anchor"
          @click="switchLocale"
        >
          {{ $t('page.preface.switchLocale') }}
        </b-button>
      </b-message>
    </div>
  </div>
</template>

<script>
import auth from '@/mixins/auth';
import validator from '@/mixins/validator';

export default {
  name: 'PagePreface',
  mixins: [
    auth,
    validator()
  ],
  methods: {
    switchLocale () {
      document.activeElement?.blur();
      this.$i18n.locale = this.$i18n.locale === 'fr' ? 'en' : 'fr';
    }
  }
};
</script>
